<template>
  <div class="temporaryParkCostRuleForm-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="queryUrl"
      v-bind="submitConfig"
      :submitMethod="submitMethod"
      :submitBefore="submitBefore"
      @update="update"
    >
      <el-form-item label="计费规则名称" prop="ruleName" :rules="[{ required: true, message: '请填写计费规则名称', trigger: 'blur' }]">
        <v-input v-model="form.ruleName"></v-input>
      </el-form-item>
      <el-form-item label="正常时段价格" prop="normalPrice" :rules="[{ required: true, message: '请填写正常时段价格', trigger: 'blur' }]">
        <v-input-number v-model="form.normalPrice" :max="100"></v-input-number>
        <span class="ml-20">元/时 (用户预约停车的时间段为正常时段。设置范围0-100间的整数)</span>
      </el-form-item>
      <el-form-item label="24小时内正常时段封顶价" label-width="200px">
        <v-input-number v-model="form.maxAmount"></v-input-number>
        <span class="ml-20">元 (24小时内车位开放的正常时段内的封顶费用，不填默认为不封顶)</span>
      </el-form-item>
      <el-form-item label="免费时长" prop="freeMinute" :rules="[{ required: true, message: '请填写免费时长', trigger: 'change' }]">
        <v-select v-model="form.freeMinute" :options="freeMinuteOps" style="display: inline-block"></v-select>
        <span class="ml-20">分钟 （即时停车中， 提交预约后至开始计费的时间段。设置范围1-30分钟）</span>
      </el-form-item>
      <el-form-item label="图文描述" prop="description" :rules="[{ required: true, message: '请输入图文描述', trigger: 'change' }]">
        <v-ueditor v-model="form.description"></v-ueditor>
      </el-form-item>
      <el-form-item label="规则状态" prop="freeMinute" :rules="[{ required: true, message: '请选择规则状态', trigger: 'change' }]">
        <v-select v-model="form.status" :options="statusOps2"></v-select>
      </el-form-item>
      <el-form-item label="包含项目" prop="communityIds" :rules="[{ required: true, validator: communityIdsVaild, trigger: 'change1' }]">
        <chosenListPanel :list.sync="communityIdsList" :disabled="!!ruleId" @select="toSelectCommunity"></chosenListPanel>
       </el-form-item>
      <multi-select
        title="项目列表"
        :isShow.sync="isShow"
        :searchUrl="getCommunityListUrl"
        :headers="communityTableHeader"
        :searchParams="searchParams"
        :responseParams="responseParams"
        :responseKey="responseKey"
        :backFill.sync="communityIdsList"
        :handleData="handleData">
          <template #searchSlot>
            <v-input label="项目名称" v-model="searchParams.communityName"></v-input>
            <v-select label="区域类型" v-model="searchParams.searchRegionType" :options="regionTypeOps"></v-select>
            <v-select2 label="所属区域" v-model="searchParams.regionId" v-bind="allRegionParams" :subjoin="{regionType : searchParams.searchRegionType}"></v-select2>
            <v-input label="所在省" v-model="searchParams.province"></v-input>
            <v-input label="所在城市" v-model="searchParams.city"></v-input>
            <v-input label="所在区" v-model="searchParams.area"></v-input>
            <!-- <v-select2 label="运营项目组" v-model="searchParams.operateRegionId" v-bind="operateRegionParams"></v-select2> -->
            <v-select label="项目阶段状态" v-model="searchParams.communityStage" :options="communityStageOps"></v-select>
            <v-select label="项目类型" v-model="searchParams.communityType" :options="communityTypeOps"></v-select>
            <v-select label="项目状态" v-model="searchParams.communityStatus" :options="communityStatusOps"></v-select>
          </template>
      </multi-select>
    </form-panel>
  </div>
</template>
<script>
import {
  uploadRuleURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL
} from './api'
import { regionTypeOps, statusOps, setCommunityStatusOps, freeMinuteOps, communityStatusMap } from './map'
import { MultiSelect, ChosenListPanel } from 'components/bussiness'
import { vUeditor } from 'components/control'
import { allRegionParams } from 'common/select2Params'
export default {
  components: {
    MultiSelect,
    ChosenListPanel,
    vUeditor
  },
  data () {
    return {
      regionTypeOps,
      statusOps,
      communityStatusOps: setCommunityStatusOps(1),
      freeMinuteOps,
      allRegionParams,
      ruleId: this.$route.query.id,
      submitMethod: this.$route.query.id ? 'put' : 'post',
      form: {
        ruleName: '',
        normalPrice: '',
        maxAmount: '',
        freeMinute: 30,
        description: '',
        status: 1,
        communityIds: '',
        sureSubmit: false
      },
      queryUrl: uploadRuleURL,
      submitConfig: {},
      communityIdsList: [],
      getCommunityListUrl: getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态'
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      isShow: false,
      searchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        operateRegionId: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: undefined,
        isNormal: 1
      },
      operateRegionParams: {
        searchUrl: getOperateRegionsURL,
        request: {
          text: 'regionName',
          value: 'operateRegionId'
        }
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      communityStageOps: [],
      communityTypeOps: []
    }
  },
  computed: {
    statusOps2 () {
      let ops = statusOps.slice(1, 3)
      return ops
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
    } else {
      this.$setBreadcrumb('新增')
    }
    this.getProjectTypes()
    this.getRoomTypes()
    this.ruleId && this.$refs.form.getData({ id: this.ruleId })
  },
  methods: {
    update (data) {
      this.form.ruleName = data.ruleName
      this.form.normalPrice = data.normalPrice
      this.form.maxAmount = data.maxAmount
      this.form.freeMinute = data.freeMinute
      this.form.description = data.description
      this.form.status = data.status
      this.form.ruleName = data.ruleName
      this.form.communityIds = data.communityId
      this.communityIdsList = [{ id: data.communityId, text: data.communityName }]
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    toSelectCommunity () {
      this.isShow = true
    },
    submitBefore (data) {
      this.ruleId && (data.id = Number(this.ruleId))
      data.communityIds = this.form.communityIds ? this.form.communityIds : this.communityIdsList.map(item => item.id)
      return true
    },
    communityIdsVaild (rule, value, callback) {
      if (this.ruleId) {
        callback()
      } else {
        if (this.communityIdsList.length > 0) {
          callback()
        } else {
          callback(new Error('请选择关联项目'))
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-20 {
  margin-left: 20px;
}
</style>
