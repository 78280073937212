var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "temporaryParkCostRuleForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              queryUrl: _vm.queryUrl,
              submitUrl: _vm.queryUrl,
              submitMethod: _vm.submitMethod,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "计费规则名称",
                prop: "ruleName",
                rules: [
                  {
                    required: true,
                    message: "请填写计费规则名称",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.ruleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ruleName", $$v)
                  },
                  expression: "form.ruleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "正常时段价格",
                prop: "normalPrice",
                rules: [
                  {
                    required: true,
                    message: "请填写正常时段价格",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input-number", {
                attrs: { max: 100 },
                model: {
                  value: _vm.form.normalPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "normalPrice", $$v)
                  },
                  expression: "form.normalPrice",
                },
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v(
                  "元/时 (用户预约停车的时间段为正常时段。设置范围0-100间的整数)"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "24小时内正常时段封顶价",
                "label-width": "200px",
              },
            },
            [
              _c("v-input-number", {
                model: {
                  value: _vm.form.maxAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "maxAmount", $$v)
                  },
                  expression: "form.maxAmount",
                },
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v(
                  "元 (24小时内车位开放的正常时段内的封顶费用，不填默认为不封顶)"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "免费时长",
                prop: "freeMinute",
                rules: [
                  {
                    required: true,
                    message: "请填写免费时长",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                staticStyle: { display: "inline-block" },
                attrs: { options: _vm.freeMinuteOps },
                model: {
                  value: _vm.form.freeMinute,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "freeMinute", $$v)
                  },
                  expression: "form.freeMinute",
                },
              }),
              _c("span", { staticClass: "ml-20" }, [
                _vm._v(
                  "分钟 （即时停车中， 提交预约后至开始计费的时间段。设置范围1-30分钟）"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "图文描述",
                prop: "description",
                rules: [
                  {
                    required: true,
                    message: "请输入图文描述",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-ueditor", {
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规则状态",
                prop: "freeMinute",
                rules: [
                  {
                    required: true,
                    message: "请选择规则状态",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.statusOps2 },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "包含项目",
                prop: "communityIds",
                rules: [
                  {
                    required: true,
                    validator: _vm.communityIdsVaild,
                    trigger: "change1",
                  },
                ],
              },
            },
            [
              _c("chosenListPanel", {
                attrs: { list: _vm.communityIdsList, disabled: !!_vm.ruleId },
                on: {
                  "update:list": function ($event) {
                    _vm.communityIdsList = $event
                  },
                  select: _vm.toSelectCommunity,
                },
              }),
            ],
            1
          ),
          _c("multi-select", {
            attrs: {
              title: "项目列表",
              isShow: _vm.isShow,
              searchUrl: _vm.getCommunityListUrl,
              headers: _vm.communityTableHeader,
              searchParams: _vm.searchParams,
              responseParams: _vm.responseParams,
              responseKey: _vm.responseKey,
              backFill: _vm.communityIdsList,
              handleData: _vm.handleData,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.isShow = $event
              },
              "update:is-show": function ($event) {
                _vm.isShow = $event
              },
              "update:backFill": function ($event) {
                _vm.communityIdsList = $event
              },
              "update:back-fill": function ($event) {
                _vm.communityIdsList = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "项目名称" },
                      model: {
                        value: _vm.searchParams.communityName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityName", $$v)
                        },
                        expression: "searchParams.communityName",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "区域类型", options: _vm.regionTypeOps },
                      model: {
                        value: _vm.searchParams.searchRegionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "searchRegionType", $$v)
                        },
                        expression: "searchParams.searchRegionType",
                      },
                    }),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            label: "所属区域",
                            subjoin: {
                              regionType: _vm.searchParams.searchRegionType,
                            },
                          },
                          model: {
                            value: _vm.searchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "regionId", $$v)
                            },
                            expression: "searchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.allRegionParams,
                        false
                      )
                    ),
                    _c("v-input", {
                      attrs: { label: "所在省" },
                      model: {
                        value: _vm.searchParams.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "province", $$v)
                        },
                        expression: "searchParams.province",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在城市" },
                      model: {
                        value: _vm.searchParams.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "city", $$v)
                        },
                        expression: "searchParams.city",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在区" },
                      model: {
                        value: _vm.searchParams.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "area", $$v)
                        },
                        expression: "searchParams.area",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目阶段状态",
                        options: _vm.communityStageOps,
                      },
                      model: {
                        value: _vm.searchParams.communityStage,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityStage", $$v)
                        },
                        expression: "searchParams.communityStage",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目类型",
                        options: _vm.communityTypeOps,
                      },
                      model: {
                        value: _vm.searchParams.communityType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityType", $$v)
                        },
                        expression: "searchParams.communityType",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目状态",
                        options: _vm.communityStatusOps,
                      },
                      model: {
                        value: _vm.searchParams.communityStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityStatus", $$v)
                        },
                        expression: "searchParams.communityStatus",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }