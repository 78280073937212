// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}temporary/park/rule/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}temporary/park/rule/export`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 新增 编辑 获取详情
const uploadRuleURL = `${API_CONFIG.butlerBaseURL}temporary/park/rule`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList?ismodal=1`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
export {
  getListURL,
  exportListURL,
  getOrgListURL,
  uploadRuleURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL
}
